import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { Form, Formik, FieldArray } from 'formik'
import { SubmitButton, ButtonArea } from 'lp-components'
import { RadioGroup, Textarea } from 'components'
import { first, isArray, keys, omit, values, reduce, isObject } from 'lodash'
import * as Yup from 'yup'
import { BOOLEAN_OPTIONS } from 'config'

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  questions: PropTypes.arrayOf(Types.zeroIncomeQuestion).isRequired,
}

const defaultProps = {}

function ZeroIncomeAccordionForm({ onSubmit, initialValues, questions }) {
  const omitValues = (values) => omit(values, ['key', 'type'])

  const cleanFormValues = (formValues) => {
    if (isArray(formValues)) {
      return formValues.map(omitValues)
    }
    // if object of arrays, then travers over the object keys and clean the array values
    if (isObject(formValues)) {
      return reduce(
        keys(formValues),
        (acc, key) => ({
          ...acc,
          [key]: formValues[key].map(omitValues),
        }),
        {}
      )
    }
    return omitValues(formValues)
  }

  const beforeSubmit = (formParams) => {
    // Assumes only one key/value pair
    const formValues = first(values(formParams))
    const formKey = first(keys(formParams))
    return onSubmit({ [formKey]: cleanFormValues(formValues) })
  }

  const validationSchema = () => {
    const zeroIncomeSchema = Yup.object().shape({
      applies: Yup.boolean().required('An answer is required'),
      additionalNotes: Yup.string()
        .nullable()
        .when('applies', {
          is: true,
          then: (schema) => schema.required('An answer is required'),
        }),
    })

    const zeroIncomeSchemas = reduce(
      questions,
      (schema, question) => ({
        ...schema,
        [question.questionType]: Yup.array().of(zeroIncomeSchema),
      }),
      {}
    )

    return Yup.object().shape({
      zero_incomes: Yup.object().shape(zeroIncomeSchemas),
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={beforeSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
    >
      {({ isSubmitting, values, isValid, submitCount }) => (
        <Form noValidate>
          {questions.map((question, questionIndex) => {
            const { questionType, label, extraNotesLabel } = question

            const formValuesIncomes = values.zero_incomes[questionType] || []

            return (
              <FieldArray
                key={questionType}
                name={`zero_incomes[${questionType}]`}
              >
                {() => (
                  <div className="row bottom-border">
                    {formValuesIncomes.map((zeroIncome, zeroIncomeIndex) => {
                      const questionNumber = questionIndex + 1

                      const handleOnChange = () => {
                        zeroIncome.additionalNotes = ''
                      }

                      return (
                        <React.Fragment key={zeroIncome.id ?? zeroIncome.key}>
                          {zeroIncomeIndex < 1 && (
                            <div className="col-12">
                              <RadioGroup
                                name={`zero_incomes[${questionType}][${zeroIncomeIndex}].applies`}
                                label={`${questionNumber}. ${label}`}
                                options={BOOLEAN_OPTIONS}
                                required
                                onChange={handleOnChange}
                              />
                            </div>
                          )}
                          {zeroIncome.applies && (
                            <div className="row sub-section sub-questions">
                              <div className="row bottom-border">
                                <div className="col-12">
                                  <Textarea
                                    name={`zero_incomes[${questionType}][${zeroIncomeIndex}].additionalNotes`}
                                    label={
                                      extraNotesLabel ||
                                      'Include a cash value of the estimated monthly expense and how the expense is paid.'
                                    }
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      )
                    })}
                  </div>
                )}
              </FieldArray>
            )
          })}
          {!isValid && submitCount > 0 && (
            <div className="row form-wide-error">
              <div className="col-12">
                <span className="error-message">
                  Please review and resolve the errors on this form and
                  resubmit.
                </span>
              </div>
            </div>
          )}
          <ButtonArea>
            <SubmitButton submitting={isSubmitting}>Save</SubmitButton>
          </ButtonArea>
        </Form>
      )}
    </Formik>
  )
}

ZeroIncomeAccordionForm.propTypes = propTypes
ZeroIncomeAccordionForm.defaultProps = defaultProps

export default React.memo(ZeroIncomeAccordionForm)
